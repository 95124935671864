<template>
  <v-col cols="12" sm="6" md="4" >
    <v-card class="mx-2 my-4 pb-1 pr-3" min-height="175">
        <a :href="store.url" style="text-decoration: none">
          <div class="d-flex">
            <v-avatar v-if="store.pagina_icon" size="79" tile class="mt-4 ml-4 mb-4">
              <img :src="store.pagina_icon" :alt="store.titulo">
            </v-avatar>
            <div style="color: black">
              <v-card-title class="pa-0 mt-2 ml-4" style="line-height: 1; word-break: break-word;">
                {{store.titulo}}
              </v-card-title>
              <v-card-text style="line-height: 1.4;">
                <span v-if="store.text">{{store.text}}</span>
                <span v-else>O que há de mais delicioso na cidade, você encontra aqui</span>
              
                  <v-badge v-if="store.status == 'aberto'" inline color="#377b42" content="Aberto"></v-badge>
                  <span v-else>
                    <v-badge inline color="#df583b" content="Fechado"></v-badge>
                    <span v-if="store.abreas" class="subtitle-2 text-center green--text">Abre às {{timeFormat(store.abreas)}}</span>
                  </span>
                </v-card-text>
            </div>
          </div>
        </a>
        <v-divider class="mx-4 mb-3"></v-divider>
        <div style="display: flex; justify-content: space-around; flex-wrap: wrap;">
          <a :href="store.url" style="text-decoration: none">
            <v-btn small :color="colorButton" dark class="mb-2">
                Ver Cardápio <div class="pa-1"></div>
                <v-icon small color="darken-2">mdi-arrow-right-bold-box</v-icon>
            </v-btn>
          </a>

          <template v-if="whatsApp[0][2] === store.codigo_cliente">
            <a :href="whatsApp[0][1]" style="text-decoration: none">
                <v-btn small color="green" dark class="mb-2">
                    <v-icon small dark>mdi-whatsapp</v-icon>&nbsp;&nbsp; |
                    {{whatsApp[0][0]}}
                </v-btn>
            </a>
          </template>

          <template v-if="whatsApp[1][2] === store.codigo_cliente">
            <a :href="whatsApp[1][1]" style="text-decoration: none">
                <v-btn small color="green" dark class="mb-2">
                    <v-icon small dark>mdi-whatsapp</v-icon>&nbsp;&nbsp; |
                    {{whatsApp[1][0]}}
                </v-btn>
            </a>
          </template>

          <template v-if="whatsApp[2][2] === store.codigo_cliente">
            <a :href="whatsApp[2][1]" style="text-decoration: none">
                <v-btn small color="green" dark class="mb-2">
                    <v-icon small dark>mdi-whatsapp</v-icon>&nbsp;&nbsp; |
                    {{whatsApp[2][0]}}
                </v-btn>
            </a>
          </template>
        </div>
      </v-card>
  </v-col>
  
</template>

<script>
  export default {
    name: 'StoreItem',
    props: ['store','colorButton','whatsApp'],
    methods:{
      timeFormat(time){
        let minute = (time.split(':'))[1]
        if(minute == '00'){
          minute = 'h'
        } else{
          minute = ':' + minute;
        }
        return (time.split(':'))[0] + minute
      }
    }
  }
</script>

<style scoped>
.button-area-link-site-time{
      display: flex;
    }
  @media(max-width: 600px){
    .button-area-link-site-time{
      flex-direction: column;
    }
    .button-area-time{
      margin-top: 7px;
    }
  }
</style>
