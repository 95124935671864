import Vue from 'vue'
import axios from 'axios'

axios.defaults.baseURL = 'https://plataforma.menuvem.com.br/application/group-0115049c7df3976546881cce199b0862/client-4b7e08e4dd4ec110e119c650323446f2/'
//https://s3-sa-east-1.amazonaws.com/nuc-storage.menuvem.com.br/application/group-1471ed47b6159f4f7ab53bddbcb75f67/img/logo-a.png

Vue.use({
    install(Vue){
        Vue.prototype.$http = axios
    }
})