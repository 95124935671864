<template>
  <StoreList/>
</template>

<script>
import StoreList from './components/StoreList';

export default {
  name: 'App',

  components: {
    StoreList,
  },

  data: () => ({
    //
  }),
};
</script>
